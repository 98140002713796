var popup;
function startOktaLogin() {

  console.log('run')
  var child = window.open('https://wj.okta.com/home/oidc_client/0oa6c8vvyfpDHWoR9417/aln177a159h7Zf52X0g8', '', 'toolbar=0,status=0,width=626,height=436');
  //var child = window.open('https://dev-84668918.okta.com/home/oidc_client/0oa7z7nbxvhQHpdbY5d7/aln177a159h7Zf52X0g8', '', 'toolbar=0,status=0,width=626,height=436');
  //var child = window.open('https://staging.eventmanager.ifexperience.it/main/#/authentication/init_okta_login', '', 'toolbar=0,status=0,width=626,height=436');
  var timer = setInterval(checkChild, 500);

  function checkChild() {
    if (child.closed) {
      console.log("Child window closed");
      clearInterval(timer);
    }
  }

}
